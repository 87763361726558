// 登录接口
import {
	axios
} from '../utils/http.js'

export function CompanyGet(data) {
	return axios({
		url: "/v3/Company/Get",
		method: 'post',
		data:data
	})
}
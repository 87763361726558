//获取数据展示中的 iframe链接所需的参数
import {
	CompanyGet
} from '@/api/companyGet.js'
import store from '@/store/index.js'

export function companyGet(){
	CompanyGet({}).then(res=>{
		//console.log('请求完成',res)
		if(res.data.Code==200){
			store.dispatch('SETDATA', {
				T_data: res.data.Data.Data.T_data,
				T_plan:res.data.Data.Data.T_plan,
				T_v3d: res.data.Data.Data.T_v3d,
				setId: res.data.Data.Data.Id,
			})
		}
	})
}
